"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _users = require("@/api/users");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _router = _interopRequireDefault(require("@/router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
var _index = _interopRequireDefault(require("@/components/FileUpload/index.vue"));
var _utils = require("@/utils");
var _countries = require("@/api/locations/countries");
var _address = require("@/api/address");
var _pincode = require("@/api/pincode");
var _states = require("@/api/locations/states");
var _cities = require("@/api/locations/cities");
// import { getOrganizations } from '@/api/organization'

let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.isGlobal = JSON.parse(process.env.VUE_APP_IS_GLOBAL);
    this.organizationList = [];
    this.userRoleList = [];
    this.countryList = [];
    this.stateList = [];
    this.cityList = [];
    this.currencyList = [{
      label: 'INR',
      key: 'INR'
    }, {
      label: 'USD',
      key: 'USD'
    }, {
      label: 'AED',
      key: 'AED'
    }];
    this.languageList = [{
      label: 'English',
      key: 'English'
    }, {
      label: 'Hindi',
      key: 'Hindi'
    }];
    this.avatar = [];
    this.gstFile = [];
    this.panFile = [];
    this.validateRequire = (rule, value, callback) => {
      if (value === '') {
        if (rule.field === 'imageURL') {
          this.$message({
            message: 'Upload cover image is required',
            type: 'error'
          });
        } else {
          this.$message({
            message: rule.field + ' is required',
            type: 'error'
          });
        }
        callback(new Error(rule.field + ' is required'));
      } else {
        callback();
      }
    };
    this.formData = Object.assign({}, _users.defaultUserData);
    this.addressFormData = Object.assign({}, _address.defaultAddressData);
    this.pincodeList = [];
    this.loading = false;
    this.userOptions = [{
      label: 'Pending',
      key: 'Pending'
    }, {
      label: 'Approved',
      key: 'Approved'
    }, {
      label: 'Rejected',
      key: 'Rejected'
    }];
    this.rules = {
      firstName: [{
        required: true,
        message: 'Please enter first name',
        trigger: 'blur'
      }, {
        pattern: /^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
        message: 'Please enter valid first name',
        trigger: 'blur'
      }, {
        min: 3,
        max: 150,
        message: 'Length should be 3 to 150',
        trigger: 'blur'
      }],
      lastName: [{
        required: true,
        message: 'Please enter last name',
        trigger: 'blur'
      }, {
        pattern: /^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
        message: 'Please enter valid first name',
        trigger: 'blur'
      }, {
        min: 3,
        max: 150,
        message: 'Length should be 3 to 150',
        trigger: 'blur'
      }],
      // companyName: [
      //   { required: true, message: 'Please enter company name', trigger: 'blur' }
      // ],
      emailAddress: [{
        required: this.isGlobal,
        message: 'Please enter email',
        trigger: 'blur'
      }, {
        type: 'email',
        // required: true,
        message: 'Please enter valid email',
        trigger: 'change'
      }],
      mobileNumber: [{
        required: !this.isGlobal,
        message: 'Please enter Mobile Number',
        trigger: 'blur'
      }, {
        min: 10,
        max: 10,
        message: 'Length should be 10 only',
        trigger: ['blur', 'change']
      }],
      password: [{
        required: true,
        message: 'Please enter Password',
        trigger: 'blur'
      }, {
        min: 6,
        message: 'Length should be greater than 6',
        trigger: ['blur', 'change']
      }],
      usersRoleId: [{
        required: true,
        message: 'Please enter choose a role',
        trigger: 'blur'
      }],
      // countryId: [
      //   {
      //     required: true,
      //     message: 'Please enter select your country',
      //     trigger: 'blur'
      //   }
      // ],
      currency: [{
        required: true,
        message: 'Please enter select your currency',
        trigger: 'blur'
      }],
      panNumber: [{
        min: 8,
        max: 19,
        message: 'Length should be between 8 to 19',
        trigger: 'blur'
      }],
      gstNumber: [
      // {
      //   required: true,
      //   message: 'Please enter GST Number',
      //   trigger: 'blur'
      // },
      {
        pattern: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
        message: 'Please enter valid GST number',
        trigger: 'blur'
      }]
    };
    this.genderOptions = [{
      label: 'Male',
      key: 'male'
    }, {
      label: 'Female',
      key: 'female'
    }, {
      label: 'Other',
      key: 'other'
    }];
  }
  get lang() {
    return _app.AppModule.language;
  }
  created() {
    // this.getOrganizationList()
    this.getUserRoleList();
    this.getCountryList();
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.formData = Object.assign({}, _users.defaultUserData);
    }
    // Why need to make a copy of this.$route here?
    // Because if you enter this page and quickly switch tag, may be in the execution of this.setTagsViewTitle function, this.$route is no longer pointing to the current page
    // https://github.com/PanJiaChen/vue-element-admin/issues/1221
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _users.getUserById)(id);
      if (data.address.length > 0) {
        this.addressFormData = data.address[0];
        if (!this.isGlobal) {
          const pincode = await (0, _pincode.getPincodeById)(this.addressFormData.pincodeId);
          this.pincodeList.push(pincode);
        } else {
          if (this.addressFormData.countryId) {
            this.getStateList();
          }
          if (this.addressFormData.stateId) {
            this.getCityList();
          }
        }
      }
      this.formData = data;
      // Just for test
      if (this.formData.avatar) {
        this.avatar.push({
          name: data.name,
          url: data.avatar
        });
      }
      if (this.formData.panFile) {
        this.panFile.push({
          name: data.name,
          url: data.panFile
        });
      }
      if (this.formData.gstFile) {
        this.gstFile.push({
          name: data.name,
          url: data.gstCertificate
        });
      }
      const title = this.formData.firstName + ' ' + this.formData.lastName || '';
      // Set tagsview title
      this.setTagsViewTitle(title);
      // Set page title
      this.setPageTitle(title);
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async getPincodeList(query) {
    const data = await (0, _pincode.getPincodes)((0, _utils.getQuery)({
      pincode: query,
      enabled: true,
      filter: {
        enabled: 'eq',
        pincode: '$starts'
      },
      sort: 'pincode,ASC',
      limit: 10
    }));
    this.pincodeList = data;
  }
  getPincodeRelated() {
    const response = [];
    if (this.addressFormData.country) {
      response.push(this.addressFormData.country.name);
    }
    if (this.addressFormData.state) {
      response.push(this.addressFormData.state.name);
    }
    if (this.addressFormData.city) {
      response.push(this.addressFormData.city.name);
    }
    return response.join(' / ');
  }
  pincodeSelected(selectedPincodeId = 0) {
    // clear city, state, country selection
    this.addressFormData.countryId = null;
    this.addressFormData.stateId = null;
    this.addressFormData.cityId = null;
    if (selectedPincodeId) {
      // find pincode
      const selectedPincode = this.pincodeList.find(pincode => pincode.id === selectedPincodeId);
      if (selectedPincode) {
        // set city, state, country
        this.addressFormData.countryId = selectedPincode.countryId;
        this.formData.countryId = selectedPincode.countryId;
        this.addressFormData.country = selectedPincode.country;
        this.addressFormData.stateId = selectedPincode.stateId;
        this.addressFormData.state = selectedPincode.state;
        this.addressFormData.cityId = selectedPincode.cityId;
        this.addressFormData.city = selectedPincode.city;
        this.addressFormData.pincodeId = selectedPincodeId;
        this.addressFormData.pincode = selectedPincode;
      }
    }
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.formData.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.formData.id}`;
  }
  certificateUploaded(file) {
    this.formData.gstCertificate = file.response;
  }
  panFileUploaded(file) {
    this.formData.panFile = file.response;
  }
  async getCountryList() {
    try {
      this.countryList = await (0, _countries.getCountries)((0, _utils.getQuery)({
        enabled: true,
        filter: {
          sort: 'name,ASC',
          enabled: 'eq'
        }
      }));
    } catch (err) {
      Promise.reject(err);
    }
  }
  async getStateList() {
    try {
      const response = await (0, _states.getStates)((0, _utils.getQuery)({
        enabled: true,
        countryId: this.addressFormData.countryId,
        filter: {
          sort: 'name,ASC',
          enabled: 'eq',
          countryId: 'eq'
        }
      }));
      this.stateList = response.data;
    } catch (err) {
      Promise.reject(err);
    }
  }
  async getCityList() {
    try {
      this.cityList = await (0, _cities.getCities)((0, _utils.getQuery)({
        enabled: true,
        stateId: this.addressFormData.stateId,
        filter: {
          sort: 'name,ASC',
          enabled: 'eq',
          stateId: 'eq'
        }
      }));
    } catch (err) {
      Promise.reject(err);
    }
  }
  async getUserRoleList() {
    try {
      this.userRoleList = await (0, _users.getUserRoles)((0, _utils.getQuery)({
        enabled: true,
        sort: 'name,ASC',
        filter: {
          enabled: 'eq'
        }
      }));
    } catch (err) {
      Promise.reject(err);
    }
  }
  fileUploaded(file) {
    this.formData.avatar = file.response;
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        if (!this.isGlobal && this.addressFormData.line1 && this.addressFormData.pincodeId) {
          this.saveForm();
        } else if (this.isGlobal && this.addressFormData.line1 && this.addressFormData.cityId) {
          this.saveForm();
        } else {
          this.$message({
            message: 'Please Add address before submit',
            type: 'warning'
          });
          return false;
        }
      } else {
        return false;
      }
    });
  }
  resetForm() {
    this.$refs.formData.resetFields();
  }
  async saveForm() {
    try {
      this.loading = true;
      if (this.isEdit) {
        this.formData.organization = {
          id: this.formData.organizationId
        };
        this.formData.usersRole = {
          id: this.formData.usersRoleId
        };
        await (0, _users.manage)(this.formData);
      } else {
        if (this.isGlobal) {
          this.formData.currency = 'USD';
        }
        delete this.formData.organization;
        delete this.formData.usersRole;
        const response = await (0, _users.register)(this.formData);
        this.formData = response.user;
      }
      this.addressFormData.name = this.formData.firstName + ' ' + this.formData.lastName || '';
      this.addressFormData.mobileNumber = this.formData.mobileNumber;
      this.addressFormData.userId = this.formData.id;
      await (0, _address.createAddresses)(this.addressFormData);
      this.$notify({
        title: 'Success',
        message: 'User added successfully',
        type: 'success',
        duration: 2000
      });
      _router.default.push('/users/list');
    } catch (err) {
      this.loading = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'AdminRoleDetail',
  components: {
    FileUpload: _index.default
  }
})], default_1);
var _default = exports.default = default_1;